<template>
  <panel title="Manage Call Node Red">
    <!-- use component -->
    <hac-form-modal
      ref="HacFormModal"
      pathImage="/api/image/"
      @reload="fetchData()"
    />
    <!-- end use component -->

    <b-row>
      <b-col sm="1">
        <btn-add class="mb-2" @clickMe="$refs.HacFormModal.show('', ranking)" />
      </b-col>
      <b-col sm="11" class="text-right">
        <btn-submit @clickMe="handelSubmit()" />
      </b-col>
    </b-row>
    <!-- draggable -->
    <draggable
      v-model="datas"
      class="list-group list-group-flush cursor-move"
      tag="ul"
      @change="updateRanking"
    >
      <transition-group type="transition" name="flip-list" v-if="datas">
        <b-list-group-item
          v-for="(data, index) in datas"
          :key="data.id"
          tag="li"
        >
          <div>
            <b-row>
              <b-col sm="6"> {{ data.title }}</b-col>
              <b-col sm="6" class="text-right">
                <!-- 
                Group Component  Button
               -->
                <btn-edit
                  text=""
                  class="mr-1"
                  @clickMe="$refs.HacFormModal.show(cp(data), null)"
                />
                <btn-delete @clickMe="clickDelete(cp(data))" />
                <!-- 
                End Group Component  Button
               -->
              </b-col>
              <b-col sm="12">
                <hr />
              </b-col>
            </b-row>
            <p v-html="parse(data.detail)" />
            <p
              class="mb-0"
              v-for="(image, i) in data.image_datas"
              :key="image.id"
            >
              <b-img
                :src="`${url}/image/${image.name}`"
                fluid
                alt="Responsive image"
                class="mr-1"
              />
            </p>
          </div>
        </b-list-group-item>
      </transition-group>
    </draggable>
  </panel>
</template>

<script>
import draggable from "vuedraggable"
import HacFormModal from "./components/HacFormModal.vue"

export default {
  created() {
    this.fetchData()
  },
  components: {
    draggable,
    HacFormModal,
  },
  data() {
    return {
      ranking: 1,
      url: process.env.VUE_APP_API_BASEURL,
      datas: [],
      list: [],
    }
  },
  methods: {
    async handelSubmit() {
      try {
        await this.api.post(`/api/admin/updateAllTimeline`, this.datas)
        await this.alert.success({ title: "Update Success" })
        this.fetchData()
      } catch (error) {
        console.error(error)
        return ""
      }
    },
    async clickDelete(data) {
      try {
        await this.api.del(`/api/admin/timeLineData/${data.id}`, "", true)
        await this.fetchData()
      } catch (error) {
        console.error(error)
        return ""
      }
    },
    updateRanking(datas) {
      this.datas.forEach((data, index) => {
        data.title = `step ${index + 1}`
        data.ranking = index + 1
      })
      this.findRanking()
    },
    parse(html) {
      try {
        return html.replace(/{{(.+?)}}/g, (_, g) => {
          return this[g.trim()]
        })
      } catch (error) {
        return html
      }
    },
    async fetchData() {
      try {
        this.datas = await this.api.get(`/api/timelineData/CallNodeRed`)
        this.findRanking()
        this.updateRanking()
      } catch (error) {
        console.error(error)
        return ""
      }
    },

    findRanking() {
      let len = this.datas.length - 1
      if (len < 0) len = 0
      this.ranking = this.datas[len].ranking
    },
  },
}
</script>

<style>
.list-group-item {
  transition: all 1s;
}
</style>
